import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import ChatLoaderImg from "../../../src/images/chat-loader.png";

// Create a div that will hold the iframe
const chatLoaderRoot = typeof document !== `undefined` ? document.createElement('div') : null;

const PersistentChatLoader = () => {
  const [isChatOpen, setChatOpen] = useState(false);

  useEffect(() => {
    // Append the chat loader div to the body so it stays across pages
    if (chatLoaderRoot) {
      document.body.appendChild(chatLoaderRoot);
    }
    return () => {
      if (chatLoaderRoot) {
        document.body.removeChild(chatLoaderRoot);
      }
    };
  }, []);

  const toggleChat = () => {
    setChatOpen(!isChatOpen);
  };

  return chatLoaderRoot
    ? ReactDOM.createPortal(
        <div className="chat-loader" style={chatLoader}>
          {!isChatOpen && (
            <button
              className="chat-icon"
              onClick={toggleChat}
              style={chatIconStyle}
            >
            <img src={ChatLoaderImg} alt="Chat Icon" />
            OOH●GPT
            </button>
          )}
          {isChatOpen && (
            <div className="chat-frame-wrapper" style={chatWrapperStyle}>
              <button
                className="close-chat"
                onClick={toggleChat}
                style={closeButtonStyle}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.688"
                  height="17.688"
                  viewBox="0 0 17.688 17.688"
                >
                  <path
                    id="close-icon"
                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#ec1c24"
                  ></path>
                </svg>
              </button>
              <iframe
                id="iframeElement"
                src="https://ooh-gpt-floating.web.app/chatbox"
                width="450"
                height="800"
                style={{ border: "0" }}
                title="Persistent Chat"
              />
            </div>
          )}
        </div>,
        chatLoaderRoot
      )
    : null;
};

const chatIconStyle = {
  position: "fixed",
  bottom: "20px",
  right: "20px",
  backgroundColor: "#ec1c24",
  borderRadius: "50%",
  padding: "15px",
  color: "#fff",
  cursor: "pointer",
  border: "none",
  fontSize: "13px",
  boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '95px',
  width: '95px',
  lineHeight: '1',
};

const chatWrapperStyle = {
  position: "fixed",
  bottom: "0",
  right: "0",
  width: "450px",
  zIndex: "1000",
};

const closeButtonStyle = {
  position: "absolute",
  top: "119px",
  right: "calc(5% + 15px)",
  backgroundColor: "transparent",
  border: "none",
  fontSize: "24px",
  cursor: "pointer",
  color: "#333",
};

const chatLoader = {
  zIndex: '9999',
  position: 'relative',
}

export default PersistentChatLoader;

