import React from "react";
import PersistentChatLoader from "./src/components/common/PersistentChatLoader";

// This wraps the root element with the persistent chat loader
export const wrapRootElement = ({ element }) => (
  <>
    {element}
    <PersistentChatLoader />
  </>
);
